import ApiService from "@/api/api.service";
import { AxiosPromise } from "axios";
import { Referral } from "@/types/Referral";
import { ReferralStatistic } from "@/types/ReferralStatistic";

export const apiGetReferral = (): AxiosPromise<Referral> => {
  return ApiService.get("api/billing/referral");
};

export const apiStartReferralProgram = (): AxiosPromise<ReferralStatistic> => {
  return ApiService.get("api/billing/referral/start");
};

export const apiLoadReferralStatic = (): AxiosPromise<ReferralStatistic> => {
  return ApiService.get("api/billing/referral/statistic");
};


import { defineComponent } from "vue";

export default defineComponent({
  name: "TheReferralStartPage",
  components: {},
  setup(props, { emit }) {
    const startProgram = () => {
      emit("start-program");
    };

    return { startProgram };
  },
});

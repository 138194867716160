
import { defineComponent } from "vue";
import { ReferralStatistic as TypeReferralStatistic } from "@/types/ReferralStatistic";
import useNotify from "@/composables/useNotify";
import { useStore } from "vuex";
import { toClipboard } from "@soerenmartius/vue3-clipboard";
import { CopyOutlined } from "@ant-design/icons-vue";
import { convertMoney } from "@/common/helper";

export default defineComponent({
  name: "TheReferralStatistics",
  components: { CopyOutlined },
  props: {
    statistic: {
      type: Object as () => TypeReferralStatistic,
      required: true,
    },
  },
  setup() {
    const notify = useNotify();
    const store = useStore();

    const openNotificationCopy = () => {
      toClipboard(`https://proip.info/?link=${store.state.auth.user.id}`);
      notify.sendNotify("success", "Copied", "Link copied to clipboard");
    };

    const converter = convertMoney;

    return { openNotificationCopy, store, converter };
  },
});

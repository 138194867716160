
import { defineComponent, ref, reactive, onMounted } from "vue";
import { ReferralStatistic } from "@/types/ReferralStatistic";
import { apiGetReferral, apiLoadReferralStatic, apiStartReferralProgram } from "@/api/referral";
import TheReferralStartPage from "@/components/TheReferralStartPage.vue";
import TheReferralStatistics from "@/components/TheReferralStatistics.vue";

export default defineComponent({
  name: "Referral",
  components: {
    TheReferralStatistics,
    TheReferralStartPage,
  },
  setup() {
    const isLoading = ref(false);
    const referral = ref(false);
    const statistic: Partial<ReferralStatistic> = reactive({});

    const loadStatistic = async () => {
      const { data } = await apiLoadReferralStatic();
      Object.assign(statistic, data);
    };

    const checkProgram = async () => {
      isLoading.value = true;
      const { data } = await apiGetReferral();
      referral.value = data.status;
      if (referral.value) {
        await loadStatistic();
      }
      isLoading.value = false;
    };

    const startProgram = async () => {
      isLoading.value = true;
      await apiStartReferralProgram();
      await loadStatistic();
      isLoading.value = false;
      referral.value = true;
    };

    onMounted(checkProgram);

    return { isLoading, referral, startProgram, statistic };
  },
});
